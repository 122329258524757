/* components/AccessDenied.css */
.access-denied-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f9fa;
  }
  
  .access-denied-content {
    text-align: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .access-denied-content h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #dc3545;
  }
  
  .access-denied-content p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
  
  .btn-go-back {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .btn-go-back:hover {
    background-color: #0056b3;
  }